import { Component, Output, EventEmitter, ElementRef, ViewChild, Renderer2, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { BehaviorSubject, Subscription, lastValueFrom } from 'rxjs';

import { Product } from 'src/app/interfaces/shop';
import { CheckoutValues, DeliveryPackageCheckout, PackageDimensions, RetreatPackageCheckout, Sell, SellLine, ShopCarSells } from 'src/app/interfaces/shopCart';
import { BuysService } from 'src/app/services/buys/buys.service';
import { CheckoutService } from 'src/app/services/buys/checkout.service';
import { CouponsService } from 'src/app/services/coupons/coupons.service';
import { GarageService } from 'src/app/services/garage/garage.service';

import { AccountService } from 'src/app/services/shared/account/account.service';
import { GeneralConfigurationService } from 'src/app/services/shared/configuration/general-configuration.service';
import { ShippingsService } from 'src/app/services/shop/shippings.service';
import { ShopCartService } from 'src/app/services/shop/shop-cart.service';
import { AddressService } from 'src/app/services/user/address/address.service';
import { ScheduleComponent } from '../schedule/schedule.component';
import { AddiService } from 'src/app/services/addi/addi.service';
import { UserprofileService } from 'src/app/services/userProfile/userprofile.service';
import { DeliveryObject, ShippingObject } from 'src/app/interfaces/shipping';
import { HttpErrorResponse } from '@angular/common/http';
import { AlliesService } from 'src/app/services/allies/allies.service';
import { DeliveryService } from 'src/app/services/shop/delivery.service';
import { environment } from 'src/environments/environment';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { PixelService } from 'src/app/services/pixel/pixel.service';
import { DOCUMENT } from '@angular/common';
import { MetaSellService } from 'src/app/services/meta_sell/meta-sell.service';
import { ContraentregaComponent } from '../contraentrega/contraentrega.component';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent {

  numberProductsInCart: number = 0;
  numberProductsInCart2: string = "";
  islogged: boolean = false;
  displaySideBarCart: boolean = false;
  displaySideBarCheckout: boolean = false;

  // @ts-ignore
  dateCart: ShopCar = null;
  // @ts-ignore
  deliveryParcelCart: ShopCar = null;
  // @ts-ignore
  deliveryCommodityCart: ShopCar = null;
  // @ts-ignore
  deliveryLiquidCart: ShopCar = null;
  // @ts-ignore
  retreatCart: ShopCar = null;
  retreatPackages: RetreatPackageCheckout[] = [];
  deliveryPackagesParcelCart: DeliveryPackageCheckout[] = [];
  deliveryPackagesCommodityCart: DeliveryPackageCheckout[] = [];
  deliveryPackagesLiquidCart: DeliveryPackageCheckout[] = [];

  // productsCart:any = null;
  percentages: any;
  products: Product[] = [];
  // outdatedProducts: Product[] = [];
  updatedProducts: { productId: number, type: number }[] = [];
  productsArray: { productId: number, units: number, date: Date, type: number }[] = []
  userAddress: { address: string, description: string } = { address: "", description: "" };
  userCityCodeDane = "";
  observaciones = "";
  productsIds = "";
  locationId = 0;
  worshipId = 1;
  workforce = 0;
  total = 0;
  totalItems = 0;
  // unverifiedProducts:any;
  productsLoaded = 3;
  // maxTrans = 7999999
  enableCheckout = true;
  loaded = false;
  // creatingTrace = false;
  isCompatibleAddress = false;
  hasShippingProducts = false;
  loadingDeliveryInfo = false;
  deliveryParcelPriceAdded = false;
  deliveryCommodityPriceAdded = false;
  deliveryLiquidPriceAdded = false;
  productsChecked = false;
  needGeneralReload = true;
  idsProducts = [];
  failLiquid = false;
  availability = new Map<string, boolean>();

  sumTotalDeliveryPrice: number = 0;
  sumTotalSubTotal: number = 0;
  sumTotalDiscount: number = 0;
  sumTotalInstallPrice: number = 0;

  @Output() displayCart = new EventEmitter<boolean>(false);

  subsNumProductsInCart: Subscription;
  dateSub!: Subscription;
  deliveryParcelSub!: Subscription;
  deliveryCommoditySub!: Subscription;
  deliveryLiquidSub!: Subscription;
  retreatSub!: Subscription;

  displayRedirect = false;
  saleNumber: number;

  showCouponDialog=false;
  couponCodeDiscount: string;
  couponDiscount: any = undefined;
  couponCodeDelivery: string;
  couponDelivery: any = undefined;
  couponCode: string;

  isMobile = false;

  type:number = 1;
  shippingDays: any = 1;

  noVehiclesToDateModal = false;

  paymentDialog = false;

  paymentMethod: any;

  allow_addi = false;

  sistecredit = false;
  contra = false;

  loadingAddi= false;

  date = new Date();

  semaphore: BehaviorSubject<number> = new BehaviorSubject(-1);
  semaphoreSubscription!: Subscription;

  _fbp: string|undefined

  sellsCreated: any[] = [];

  sellsIdChecked: number[] = [];

  @ViewChild('addi') addi!: ElementRef;

  sistecreditDialog = false;

  idTypes = [ {value: 'CC'}, {value: 'CE'}];
  idType: any;
  idnumber: any;
  loadingSiste = false;

  addiDialog = false;
  email = "";


  constructor(
    public router: Router,
    private accountInfo: AccountService,
    private shopCartService: ShopCartService,
    private shippingsService: ShippingsService,
    private buysService: BuysService,
    private checkoutService: CheckoutService,
    private addressService: AddressService,
    private couponService: CouponsService,
    private messageService: MessageService,
    private generalConfigurationService: GeneralConfigurationService,
    private garageService: GarageService,
    public dialogService: DialogService,
    private addiService: AddiService,
    private userProfileService: UserprofileService,
    private alliesService: AlliesService,
    private delivery_service: DeliveryService,
    private gtmService: GoogleTagManagerService,
    private pixel: PixelService,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document,
    public metaSellService: MetaSellService
  ) { }

  async ngOnInit() {
    this._fbp = this.generalConfigurationService.getCookie('_fbp');
    this.isMobile = this.generalConfigurationService.isMobile;
    this.islogged = await this.accountInfo.isLogged();
    this.getSubscriptions();
    this.semaphoreSubscription = this.semaphore.subscribe((data) => {
      if (data === 0) {
        this.afterPayment();
      }
    });
  }

  ngOnDestroy() {
    if (this.subsNumProductsInCart) {
      this.subsNumProductsInCart.unsubscribe();
    }
    if (this.dateSub) {
      this.dateSub.unsubscribe();
    }
    if (this.deliveryCommoditySub) {
      this.deliveryCommoditySub.unsubscribe();
    }
    if (this.deliveryParcelSub) {
      this.deliveryParcelSub.unsubscribe();
    }
    if (this.deliveryLiquidSub) {
      this.deliveryLiquidSub.unsubscribe();
    }
    if (this.retreatSub) {
      this.retreatSub.unsubscribe();
    }
  }

  getSubscriptions() {
    this.subsNumProductsInCart = this.shopCartService.$numProductsInCart.subscribe(data => {
      this.numberProductsInCart = data;
      this.numberProductsInCart2 = this.numberProductsInCart.toString();
    });
  }

  openSidebarCart() {
    this.displayCart.emit(true);
    this.displaySideBarCart = true;
  }

  checkAddi(){
    this.addiService.checkPrices(this.total).subscribe((data) => {
      if(this.total < data.maxAmount && this.total > data.minAmount){
        this.allow_addi = true;
        let html = '<addi-widget price=\"' + this.total.toString() + '\" ally-slug=\"zaito-ecommerce\"></addi-widget>'
        this.addi.nativeElement.innerHTML = html;
      }else{
        this.allow_addi = false;
      }
    })
  }


  initSideBarCart() {
    this.initializeVariables();
    this.loaded = false;
    this.deliveryCommoditySub = this.shopCartService.deliveryCommodityCar.subscribe(
      (deliveryCommodityCar) => {
        this.deliveryCommodityCart = deliveryCommodityCar;
        this.productsLoaded--;
        if ((this.productsLoaded <= 0 && this.needGeneralReload) || this.type == 0) {
          this.getProducts();
        } else {
          this.needGeneralReload = true;
          this.loaded = true;
        }
      });
    this.deliveryLiquidSub = this.shopCartService.deliveryLiquidCar.subscribe(
      (deliveryLiquidCar) => {
        this.deliveryLiquidCart = deliveryLiquidCar;
        this.productsLoaded--;
        if ((this.productsLoaded <= 0 && this.needGeneralReload) || this.type == 0) {
          this.getProducts();
        } else {
          this.needGeneralReload = true;
          this.loaded = true;
        }
      });
    this.deliveryParcelSub = this.shopCartService.deliveryParcelCar.subscribe(
      (deliveryParcelCar) => {
        this.deliveryParcelCart = deliveryParcelCar;
        this.productsLoaded--;
        if ((this.productsLoaded <= 0 && this.needGeneralReload) || this.type == 0) {
          this.getProducts();
        } else {
          this.needGeneralReload = true;
          this.loaded = true;
        }
      });
  }

  hideSideBarCart(e: Event) {
    this.displayCart.emit(false);
  }

  initializeVariables() {
    this.sumTotalSubTotal = 0;
    this.sumTotalDeliveryPrice = 0;
    this.sumTotalInstallPrice = 0;
    this.sumTotalDiscount = 0;
    this.productsLoaded = 3;
  }

  private getProducts(): void {
    this.loaded = false;
    this.totalItems = 0;
    this.productsIds = "";
    this.hasShippingProducts = false;
    this.total = 0;
    if (this.dateCart && this.dateCart.sells.length > 0) {
      this.dateCart.sells[0].sellLines.forEach((line: any) => {
        this.totalItems++;
        this.productsIds = this.productsIds + line.product + ",";
      });
      this.worshipId = this.dateCart?.sells[0].workshop;
    } else {
      if (this.deliveryParcelCart?.sells.length) {
        this.hasShippingProducts = true;
        this.deliveryParcelCart.sells.forEach((sell: any) => {
          sell.sellLines.forEach((line: any) => {
            this.totalItems++;
            this.productsIds = this.productsIds + line.product + ",";
          });
        });
        this.worshipId = this.deliveryParcelCart?.sells[0].workshop;
      }
      if (this.deliveryCommodityCart?.sells.length) {
        this.hasShippingProducts = true;
        this.deliveryCommodityCart.sells.forEach((sell: any) => {
          sell.sellLines.forEach((line: any) => {
            this.totalItems++;
            this.productsIds = this.productsIds + line.product + ",";
          });
        });
        this.worshipId = this.deliveryCommodityCart?.sells[0].workshop;
      }
      if (this.deliveryLiquidCart?.sells.length) {
        this.hasShippingProducts = true;
        this.deliveryLiquidCart.sells.forEach((sell: any) => {
          sell.sellLines.forEach((line: any) => {
            this.totalItems++;
            this.productsIds = this.productsIds + line.product + ",";
          });
        });
        this.worshipId = this.deliveryLiquidCart.sells[0].workshop;
      }
      if (this.retreatCart?.sells.length) {
        this.retreatCart.sells.forEach((sell: any) => {
          sell.sellLines.forEach((line: any) => {
            this.totalItems++;
            this.productsIds = this.productsIds + line.product + ",";
          });
        });
        this.worshipId = this.retreatCart.sells[0].workshop;
      }
    }
    if (this.productsIds !== "") {
      this.setProductsArray();
      this.shopCartService.loadProducts(this.productsIds.substring(0, this.productsIds.length - 1)).then(async (products: any) => {
        this.products = products;
        await this.setPackageInformation("allTypes");
        this.loaded = true;
        this.needGeneralReload = true;
        if (!this.productsChecked) {
          await this.checkChanged();
        }
      }).catch(error => { console.error(error); });
    } else {
      this.loaded = true;
    }
  }

  private setProductsArray(): void {
    this.productsArray = [];
    this.dateCart?.sells.forEach((sell: any) => {
      sell.sellLines.forEach((sellLine: any) => {
        const data: any = {
          productId: sellLine.product,
          units: sellLine.units,
          date: sellLine.date,
          type: this.dateCart.type
        }
        this.productsArray.push(data)
      });
    });
    this.retreatCart?.sells.forEach((sell: any) => {
      sell.sellLines.forEach((sellLine: any) => {
        const data: any = {
          productId: sellLine.product,
          units: sellLine.units,
          date: sellLine.date,
          type: this.retreatCart.type
        }
        this.productsArray.push(data)
      });
    });
    this.deliveryCommodityCart?.sells.forEach((sell: any) => {
      sell.sellLines.forEach((sellLine: any) => {
        const data: any = {
          productId: sellLine.product,
          units: sellLine.units,
          date: sellLine.date,
          type: this.deliveryCommodityCart.type
        }
        this.productsArray.push(data)
      });
    });
    this.deliveryParcelCart?.sells.forEach((sell: any) => {
      sell.sellLines.forEach((sellLine: any) => {
        const data: any = {
          productId: sellLine.product,
          units: sellLine.units,
          date: sellLine.date,
          type: this.deliveryParcelCart.type
        }
        this.productsArray.push(data)
      });
    });
    this.deliveryLiquidCart?.sells.forEach((sell: any) => {
      sell.sellLines.forEach((sellLine: any) => {
        const data: any = {
          productId: sellLine.product,
          units: sellLine.units,
          date: sellLine.date,
          type: this.deliveryLiquidCart.type
        }
        this.productsArray.push(data)
      });
    });
  }

  private async setPackageInformation(sellType: number | "allTypes"): Promise<void> {
    const setRetreatPackage = sellType === 0 || sellType === "allTypes";
    const setDeliveryPackage = sellType === 1 || sellType === "allTypes";
    this.retreatPackages = setRetreatPackage ? [] : this.retreatPackages;
    this.deliveryPackagesParcelCart = setDeliveryPackage ? [] : this.deliveryPackagesParcelCart;
    this.deliveryPackagesCommodityCart = setDeliveryPackage ? [] : this.deliveryPackagesCommodityCart;
    try {
      let currentAddress: any = null;
      const isDeliveryParcelCart = this.deliveryParcelCart.sells.length > 0;
      const isDeliveryCommodityCart = this.deliveryCommodityCart.sells.length > 0;
      if (isDeliveryParcelCart || isDeliveryCommodityCart) {
        this.loadingDeliveryInfo = true;
        currentAddress = await this.setUserAddress();
      }
      if (isDeliveryParcelCart) {
        this.deliveryPackagesParcelCart = await this.setPackageDeliveryInformation(this.deliveryParcelCart, currentAddress, false, true);
        this.deliveryParcelPriceAdded = false;// Para que solo sume el envío una vez
      }
      if (isDeliveryCommodityCart) {
        this.deliveryPackagesCommodityCart = await this.setPackageDeliveryInformation(this.deliveryCommodityCart, currentAddress, false, false);
        this.deliveryCommodityPriceAdded = false;// Para que solo sume el envío una vez
      }
      this.loadingDeliveryInfo = false;
    } catch (error) { }
  }

  private async setAllyAddress(allyId: number): Promise<string> {
    const data: any = this.products.find((p: any) => p.workship_idworkship.idworkship === allyId)
    const city: any = data.workship_idworkship.city as Location;
    return city.name;
  }

  private getProductInfo(allyId: number, productId: number, units: number, variant: number): CheckoutValues {
    const product = this.products.filter((p) => p.idproduct === productId);
    const productAux: any = product.find((p: any) => p.workship_idworkship.idworkship === allyId);
    const tax = productAux.tax ? productAux.tax : 0;
    const productPrice = productAux.prize * (1 + tax / 100);
    let variantName = '';
    if(variant){
      const variantProduct =  productAux.variants.filter((data: any) => {
        return data.id == variant
      })
      variantName = variantProduct[0].cloth_size;
    }
    let discount = 0;
    if (productAux.productAd.length !== 0) {
      if (productAux.productAd[0].type === 1) {
        discount = productPrice * (productAux.productAd[0].discount / 100);
      } else if (productAux.productAd[0].type === 2) {
        discount = productAux.productAd[0].offer;
      }
    }else if (this.couponDiscount){
      discount = productPrice * (this.couponDiscount.campaing.promotion_value / 100);
    }
    // @ts-ignore
    let dimensions: PackageDimensions = null;
    if (productAux.dimensions !== null) {
      dimensions = {
        alto: productAux.dimensions.height,
        ancho: productAux.dimensions.width,
        largo: productAux.dimensions.length,
        peso: productAux.dimensions.weight,
        unidades: units
      };
    } else {
      dimensions = {
        alto: 0,
        ancho: 0,
        largo: 0,
        peso: 0,
        unidades: units
      };
    }
    const data: CheckoutValues = {
      name: productAux.name,
      price: productPrice,
      discount,
      installPrice: productAux.install_prize * (1+ (productAux.install_tax/ 100)),
      installTax: productAux.install_tax,
      dimensions,
      idproduct: productId,
      variant,
      variantName
    };
    return data;
  }

  private getSubtotal(checkoutValues: CheckoutValues[]): number {
    let subTotal = 0;
    checkoutValues.forEach((value: any) => {
      subTotal = subTotal + ((value.price - value.discount) + (this.type == 2 ? value.installPrice : 0))  * value.dimensions.unidades;
    });
    return subTotal;
  }

  private async setUserAddress(): Promise<any> {
    try {
      const resp = await this.addressService.getLocation();
      if (resp.length !== 0) {
        let currentAddress = resp.find((data: any) => data.current === true);
        if (resp.length === 1) {
          currentAddress = resp[0];
          currentAddress.current = true;
        }
        const destDaneCode: string = currentAddress.location.dane_code;
        this.userAddress = {
          address: currentAddress.address,
          description: currentAddress.description
        };
        this.userCityCodeDane = currentAddress.location.dane_code;
        this.observaciones = currentAddress.description;
        this.locationId = currentAddress.location.id;
        return currentAddress;
      } else {
        // this.openUserDirections();
        return null;
      }
    } catch (error) {
      // this.toastService.toastErrorConnection("Error de conexión")
    }
  }

  async setPackageDeliveryInformation(deliveryCart: any, currentAddress: any, isLiquid: boolean, isParcel: boolean): Promise<any[]> {
    let destDaneCode = "";
    const myArray: DeliveryPackageCheckout[] = [];
    if (deliveryCart && currentAddress) {
      destDaneCode = currentAddress.location.dane_code;
      for (let index = 0; index < deliveryCart.sells.length; index++) {
        const sell = deliveryCart.sells[index];
        const deliveryCheckout: CheckoutValues[] = [];
        const deliveryCheckoutToShow: CheckoutValues[] = [];
        this.idsProducts = [];
        sell.sellLines.forEach((sellLine: any) => {
          const aux: CheckoutValues = this.getProductInfo(sell.workshop, sellLine.product, sellLine.units, sellLine.variant);
          deliveryCheckout.push(aux);
          // @ts-ignore
          this.idsProducts.push(sellLine.product)
          for (let i = 0; i < sellLine.units; i++) {
            const newData: CheckoutValues = {
              name: aux.name,
              price: aux.price,
              discount: aux.discount,
              installPrice: aux.installPrice,
              installTax: aux.installTax,
              variant: aux.variant ? aux.variant : undefined,
              variantName: aux.variantName ? aux.variantName : '',
              idproduct: aux.idproduct
            };
            deliveryCheckoutToShow.push(newData);
          }
        });
        const origDaneCode = this.getShopOrigDaneCode(sell.workshop);
        const subTotal = this.getSubtotal(deliveryCheckout);
        let deliveryInfo = await this.getDeliveryInfo(deliveryCheckout, destDaneCode, origDaneCode, subTotal, this.idsProducts, isLiquid, isParcel);
        /* let percentages = this.buysService.getPercentages(this.product.idproduct, destDaneCode) */
        if (deliveryInfo === null) {
          deliveryInfo = [0, 0, 0];
        }
        const data: DeliveryPackageCheckout = {
          sellIndex: index,
          origDaneCode,
          // @ts-ignore
          deliveryPrice: this.type == 1 ? deliveryInfo[0] : 0,
          // @ts-ignore
          shippingDays: this.type == 1 ? deliveryInfo[1] : 0,
          // @ts-ignore
          subTotal: subTotal + (this.type == 1 ? deliveryInfo[0] : 0),
          package: deliveryCheckout,
          packageToShow: deliveryCheckoutToShow,
          // @ts-ignore
          realDeliverPrice: this.type == 1 ? deliveryInfo[2]: 0,
        };
        this.shippingDays = data.shippingDays;
        myArray.push(data);
      }
    }else if(deliveryCart){
      for (let index = 0; index < deliveryCart.sells.length; index++) {
        const sell = deliveryCart.sells[index];
        const deliveryCheckout: CheckoutValues[] = [];
        const deliveryCheckoutToShow: CheckoutValues[] = [];
        this.idsProducts = [];
        sell.sellLines.forEach((sellLine: any) => {
          const aux: CheckoutValues = this.getProductInfo(sell.workshop, sellLine.product, sellLine.units, sellLine.variant);
          deliveryCheckout.push(aux);
          // @ts-ignore
          this.idsProducts.push(sellLine.product)
          for (let i = 0; i < sellLine.units; i++) {
            const newData: CheckoutValues = {
              name: aux.name,
              price: aux.price,
              discount: aux.discount,
              installPrice: aux.installPrice,
              installTax: aux.installTax,
              variant: aux.variant ? aux.variant : undefined,
              variantName: aux.variantName ? aux.variantName : '',
              idproduct: aux.idproduct
            };
            deliveryCheckoutToShow.push(newData);
          }
        });
        const subTotal = this.getSubtotal(deliveryCheckout);
        let deliveryInfo: any = null;
        /* let percentages = this.buysService.getPercentages(this.product.idproduct, destDaneCode) */
        if (deliveryInfo === null) {
          deliveryInfo = [0, 0, 0];
        }
        const data: DeliveryPackageCheckout = {
          sellIndex: index,
          origDaneCode: "0",
          // @ts-ignore
          deliveryPrice: this.type == 1 ? deliveryInfo[0] : 0,
          // @ts-ignore
          shippingDays: this.type == 1 ? deliveryInfo[1] : 0,
          // @ts-ignore
          subTotal: subTotal + (this.type == 1 ? deliveryInfo[0] : 0),
          package: deliveryCheckout,
          packageToShow: deliveryCheckoutToShow,
          // @ts-ignore
          realDeliverPrice: this.type == 1 ? deliveryInfo[2]: 0,
        };
        this.shippingDays = data.shippingDays;
        myArray.push(data);
      }
    }
    return myArray
  }

  private getShopOrigDaneCode(allyId: number): string {
    const product = this.products.filter((p: any) => p.workship_idworkship.idworkship === allyId);
    // tslint:disable-next-line: no-string-literal
    // @ts-ignore
    const daneCode: string = product[0].workship_idworkship.city["dane_code"];
    return daneCode;
  }

  private redondearAlPiso(numero: number): number {
    const factor = 100; // establece el factor de redondeo, en este caso 100 para los últimos dos dígitos
    return Math.floor(numero / factor) * factor; // divide el número por el factor, aplica Math.floor y luego multiplica por el factor
  }

  private async getDeliveryInfo(checkoutValues: CheckoutValues[], destDaneCode: string,
    origDaneCode: string, subTotal: number, idsProdcuts: number[], isLiquid: boolean, isParcel: boolean): Promise<number[] | void> {
    try {
      const worth = subTotal.toString();
      let dimensions: PackageDimensions[] = [];
      let maxVolume = 0
      checkoutValues.forEach((c: any) => {
        const volume = c.dimensions.alto * c.dimensions.ancho * c.dimensions.largo
        if (volume > maxVolume){
          maxVolume = volume
          dimensions = [c.dimensions]
        }
      });
      const promises: Promise<any>[] = [];
      const is_liquid = isLiquid ? true : false;
      promises.push(this.shippingsService.getShippingPackageQuotation(origDaneCode, destDaneCode, worth, dimensions, is_liquid, isParcel));
      if(this.couponDelivery){
        promises.push(this.buysService.getPercentage(4));
      }else{
        promises.push(this.buysService.getPercentageWithPrice(idsProdcuts, subTotal, destDaneCode));
      }
      return await Promise.all(promises).then(async (values) => {
        const data = values[0];
        const quoteInfo = data.Cotizador_cotizarResult;
        this.percentages = values[1];
        if (quoteInfo) {
          const realShippingPrice = parseInt(quoteInfo.flete_total, 10);
          let shippingPrice = 0;
          if(values[1].fixed_delivery_price){
            shippingPrice = values[1].fixed_delivery_price;
          }else{
            shippingPrice = this.redondearAlPiso(Math.ceil(parseInt(quoteInfo.flete_total) * (values[1].user_shipping / 100)));
          }
          /* shippingPrice = this.redondearAlPiso(Math.ceil(parseInt(quoteInfo.flete_total) * (values[1].user_shipping / 100))); */
          const shippingDays = parseInt(quoteInfo.dias_entrega, 10) + 1;
          this.isCompatibleAddress = true;
          return [shippingPrice, shippingDays, realShippingPrice];
        } else {
          this.isCompatibleAddress = false;
          this.loadingDeliveryInfo = false;
          this.failLiquid = is_liquid ? true : false;
          return [0, 0, 0];
        }
      }).catch(error => {
        this.isCompatibleAddress = false;
        this.loadingDeliveryInfo = false;
        this.failLiquid = is_liquid ? true : false;
        console.error(error)
        // this.toastService.toastErrorConnection("Error de conexión");
      });
    } catch (error) {
      console.log(error);
    }
  }

  private async checkChanged(): Promise<boolean> {
    let tittle = "";
    let message = "*";
    const productsChanged = this.products.filter((p: any) => {
      const typeChanged = this.checkProductType(p);
      if (p.update_date !== null) {
        const cartInfo = this.getCartInfo(p.idproduct);
        const cartDate = new Date(cartInfo.date);
        const updatedDate = new Date(p.update_date);
        const updated = updatedDate > cartDate;
        this.changeCartDate(p.idproduct, updatedDate);
        if (updated) {
          this.updatedProducts.push({ productId: p.idproduct, type: cartInfo.type })
        }
        return updated || typeChanged;
      } else {
        return typeChanged
      }
    });
    this.productsChecked = true;
    if (productsChanged.length > 0) {
      tittle = "Cambios en Producto y/o Servicio";
      productsChanged.forEach((product, i) => {
        const final = i + 1 === productsChanged.length ? " " : ", ";
        message = message + product.name + final;
      });
      if (productsChanged.length > 1) {
        message = message + "han sido modificados por el Vendedor, "
          + "te invitamos a verificarlos de nuevo antes de proceder con la compra.";
      } else {
        message = message + "ha sido modificado por el Vendedor, "
          + "te invitamos a verificarlo de nuevo antes de proceder con la compra.";
      }

      // const alertAcepted = await this.cartAlertsService.showAlert(tittle, message);
      // if (alertAcepted && this.updatedProducts.length > 0) {
      // //   this.shopCarService.updateDateInCart(this.updatedProducts);
      // }
      // // return alertAcepted;
      return true;
    } else {
      return false;
    }
  }

  private checkProductType(product: any): boolean {
    const idproduct = product.idproduct;
    const productAux: any = this.productsArray.find(p => p.productId === idproduct);
    const retreatMismatch = productAux.type === 0 && product.get_in_workship === false;
    const deliveryMismatch = productAux.type === 1 && product.delivery === false;
    const dateMismatch = productAux.type === 2 && product.need_date === false;
    return retreatMismatch || deliveryMismatch || dateMismatch;
  }

  private getCartInfo(idproduct: number): { date: Date, type: number } {
    const productAux = this.productsArray.find(p => p.productId === idproduct);
    // @ts-ignore
    return { date: productAux.date, type: productAux.type };
  }

  private changeCartDate(idproduct: number, date: Date): void {
    const productAux = this.productsArray.find(p => p.productId === idproduct);
    // @ts-ignore
    productAux.date = date;
  }

  getProduct(id: number): Product {
    // @ts-ignore
    return this.products.find((p) => p.idproduct === id);
  }

  sumTotal(prodValue: any[]): void {
    let delivery = false;
    this.deliveryPackagesParcelCart.forEach((data) => {
      let pakages = data.package;
        pakages.forEach((pakage: any) => {
        if (pakage.idproduct === prodValue[1] && pakage.variant === prodValue[5]) {
          let delyveryPrice = 0;
          if(this.couponDelivery || this.couponDiscount){
            if(this.couponDiscount){
              delyveryPrice = this.deliveryParcelPriceAdded ? 0 : (this.type == 1 ? data.deliveryPrice : 0);
              this.sumTotalDiscount +=  (pakage.price * (this.couponDiscount.campaing.promotion_value / 100)) * pakage.dimensions.unidades
              const offer = this.couponDiscount.campaing.promotion_value / 100;
              this.total += (prodValue[0] - (prodValue[0]  * offer)) + delyveryPrice;
            }else{
              delyveryPrice = 0;
              this.sumTotalDiscount += pakage.discount * pakage.dimensions.unidades;
              this.total += prodValue[0] + delyveryPrice;
            }
          }else{
            this.sumTotalDiscount += pakage.discount * pakage.dimensions.unidades;
            delyveryPrice = this.deliveryParcelPriceAdded ? 0 : (this.type == 1 ? data.deliveryPrice : 0);
            this.total += prodValue[0] + delyveryPrice;
          }
          if(this.type == 2){
            this.total += (pakage.installPrice* pakage.dimensions.unidades)
          }
          // Para que solo sume el envío una vez
          this.sumTotalDeliveryPrice += delyveryPrice;
          this.sumTotalInstallPrice += (pakage.installPrice * pakage.dimensions.unidades);
          this.sumTotalSubTotal += pakage.price * pakage.dimensions.unidades;
          this.deliveryParcelPriceAdded = true;
          delivery = true;
        }
      });
    });
    this.deliveryPackagesCommodityCart.forEach((data) => {
      let pakages = data.package;
      pakages.forEach((pakage: any) => {
        if (pakage.idproduct === prodValue[1] && pakage.variant === prodValue[5]) {
          let delyveryPrice = 0;
          if(this.couponDelivery || this.couponDiscount){
            if(this.couponDiscount){
              delyveryPrice = this.deliveryParcelPriceAdded ? 0 : (this.type == 1 ? data.deliveryPrice : 0);
              this.sumTotalDiscount +=  (pakage.price * (this.couponDiscount.campaing.promotion_value / 100)) * pakage.dimensions.unidades
              const offer = this.couponDiscount.campaing.promotion_value / 100;
              this.total += (prodValue[0] - (prodValue[0]  * offer)) + delyveryPrice;
            }else{
              delyveryPrice = 0;
              this.sumTotalDiscount += pakage.discount * pakage.dimensions.unidades;
              this.total += prodValue[0] + delyveryPrice;
            }
          }else{
            this.sumTotalDiscount += pakage.discount * pakage.dimensions.unidades;
            delyveryPrice = this.deliveryParcelPriceAdded ? 0 : (this.type == 1 ? data.deliveryPrice : 0);
            this.total += prodValue[0] + delyveryPrice;
          }
          // Para que solo sume el envío una vez
          this.sumTotalDeliveryPrice += delyveryPrice;
          this.sumTotalSubTotal += pakage.price * pakage.dimensions.unidades;
          this.deliveryParcelPriceAdded = true;
          delivery = true;
        }
      });
    });
    
    /* this.deliveryPackagesLiquidCart.forEach((data) => {
      let pakages = data.package;
      pakages.forEach((pakage: any) => {
        if (pakage.idproduct === prodValue[1] && pakage.variant === prodValue[5]) {
          let delyveryPrice = 0;
          if(this.couponDelivery || this.couponDiscount){
            if(this.couponDiscount){
              delyveryPrice = this.deliveryParcelPriceAdded ? 0 : (this.type == 1 ? data.deliveryPrice : 0);
              this.sumTotalDiscount +=  (pakage.price * (this.couponDiscount.campaing.promotion_value / 100)) * pakage.dimensions.unidades
              this.total += (prodValue[0]  * (this.couponDiscount.campaing.promotion_value / 100)) + delyveryPrice;
            }else{
              delyveryPrice = 0;
              this.sumTotalDiscount += pakage.discount * pakage.dimensions.unidades;
              this.total += prodValue[0] + delyveryPrice;
            }
          }else{
            this.sumTotalDiscount += pakage.discount * pakage.dimensions.unidades;
            delyveryPrice = this.deliveryParcelPriceAdded ? 0 :(this.type == 1 ? data.deliveryPrice : 0);
            this.total += prodValue[0] + delyveryPrice;
          }
          // Para que solo sume el envío una vez
          this.sumTotalDeliveryPrice += delyveryPrice;
          this.sumTotalSubTotal += pakage.price * pakage.dimensions.unidades;
          this.deliveryParcelPriceAdded = true;
          delivery = true;
        }
      });
    }); */
    /* if (!delivery) {
      if (this.dateCart?.sells.length > 0) {
        this.products.forEach((product: any) => {
          if (product.idproduct === prodValue[1] ) {
            this.sumTotalSubTotal += prodValue[0];
            this.total += prodValue[0] + product.install_prize * (1 + product.install_tax / 100);
            this.workforce += product.install_prize * (1 + product.install_tax / 100);
          }
        })
      } else {
        this.sumTotalSubTotal += prodValue[4];
        this.sumTotalDiscount += prodValue[2] > 0 ? prodValue[2] : prodValue[3];
        this.total += prodValue[0];
      }
    } */
  }

  removeItemInCart(item: { product: number, type: number, price: number, is_liquid: boolean }, workshop: number, index: number, weight?: any): void {
    const weigth = weight;
    const mapKey = this.setAvailabilityMapKey(item.type, item.product, index);
    this.total = this.total - item.price;
    this.totalItems--;
    this.availability.delete(mapKey);
    this.workforce = 0;
    this.sumTotalSubTotal = this.sumTotalSubTotal - item.price;
    this.sumTotalDeliveryPrice = 0;
    this.sumTotalInstallPrice = 0;
    this.needGeneralReload = true;
    this.getAvailability();
    this.shopCartService.removeItemInCar(item.type, workshop, index, weigth, item.is_liquid);
    this.sumTotalSubTotal = 0;
    this.setPackageInformation(item.type);
  }

  private setAvailabilityMapKey(type: number, productId: number, index: number): string {
    return type === 2 ? `${type}${productId}/${index}` : `${type}${productId}`;
  }

  private setNewAvailabilityMap(): void {
    const keys = this.availability.keys();
    const values = this.availability.values();
    let auxKeyArray: string[] = [];
    // @ts-ignore
    let auxValuesArray: any[] = [];
    let loop = false
    while (!loop) {
      const nextKey = keys.next();
      const nextValue = values.next();
      if (!nextKey.done) {
        auxKeyArray.push(nextKey.value);
        auxValuesArray.push(nextValue.value);
      }
      // @ts-ignore
      loop = nextKey.done
    }
    this.availability.clear();
    auxKeyArray.forEach((key, index) => {
      const newKey = key.split("/")[0] + "/" + index;
      // @ts-ignore
      this.availability.set(newKey, auxValuesArray[index]);
    });
  }

  getAvailability(): void {
    this.enableCheckout = true;
    this.availability.forEach((avalaible) => {
      this.enableCheckout = this.enableCheckout && avalaible;
    });
  }

  updateTotal(update: { oldPrice: number, newPrice: number, sellType: number },
    line: { product: number, units: number, date?: Date }): void {
    this.updateUnitsInProductsArray(line);
    this.setPackageInformation(update.sellType);
    this.initializeVariables();
    this.getProducts();
 /* else {
      this.total = this.total - update.oldPrice;
      this.total += update.newPrice;
    } */
    this.shopCartService.saveCar();
  }

  private updateUnitsInProductsArray(line: { product: number, units: number, date?: Date }): void {
    const index = this.productsArray.findIndex(p => p.productId === line.product);
    const auxDate = this.productsArray[index].date;
    const auxType = this.productsArray[index].type;
    this.productsArray[index] = { productId: line.product, units: line.units, date: auxDate, type: auxType };
  }

  setAvailability(type: number, productId: number, available: boolean, index: number): void {
    const mapKey = this.setAvailabilityMapKey(type, productId, index);
    this.availability.set(mapKey, available);
    if (this.availability.size === this.totalItems) {
      this.getAvailability();
    }
  }

  async initSideBarCheckout(e: Event) { }

  async setInfomationCheckout() {
    this.checkoutService.retreatCart = this.retreatCart;
    this.checkoutService.deliveryParcelCart = this.deliveryParcelCart;
    this.checkoutService.deliveryCommodityCart = this.deliveryCommodityCart;
    this.checkoutService.deliveryLiquidCart = this.deliveryLiquidCart;
    this.checkoutService.deliveryPackagesLiquidCart = this.deliveryPackagesLiquidCart;
    this.checkoutService.dateCart = this.dateCart;
    this.checkoutService.products = this.products;
    this.checkoutService.retreatPackages = this.retreatPackages;
    this.checkoutService.deliveryPackagesParcelCart = this.deliveryPackagesParcelCart;
    this.checkoutService.deliveryPackagesCommodityCart = this.deliveryPackagesCommodityCart;
    this.checkoutService.userAddress = this.userAddress;
    this.checkoutService.userCityCodeDane = this.userCityCodeDane;
    this.checkoutService.observaciones = this.observaciones;
    this.checkoutService.locationId = this.locationId;
    this.checkoutService.couponDelivery = this.couponDelivery;
    this.checkoutService.couponDiscount = this.couponDiscount;
    this.checkoutService.type = this.type;
  }

  async goToCheckout() {
    if(this.islogged){
      if(this.type == 2){
        let garage = await this.garageService.getState()
        const actualAmountVehicles = garage.length
        if (actualAmountVehicles > 0){
          await this.setInfomationCheckout();
          this.openSchedule();
        }else{
          this.noVehiclesToDateModal = true;
        }
      }else{
        await this.setInfomationCheckout();
        this.checkAddi();
        this.paymentDialog = true
        /* this.displaySideBarCheckout = true; */
      }
    }else{
      this.router.navigate(["/checkout"]);
    }
  }

  get roundSubTotal(){
    return Math.round(this.total);
  }

  openSchedule(){
    let height = "";
    let width = "35%"
    if (window.innerWidth < 1400){
      height = '90vh'
      width = "50%"
    }
    const productsAux: any[] = [];
    let discounts= new Map<number, number>();
    const variants: any[] = []
    this.deliveryPackagesParcelCart.forEach((data) => {
      data.packageToShow.forEach((packaget) => {
        const product = this.products.filter((p) => p.idproduct === packaget.idproduct);
        productsAux.push(product[0]);
        variants.push(packaget.variant);
        discounts.set(product[0].idproduct as number, packaget.discount);
      })
    })
    this.deliveryPackagesCommodityCart.forEach((data) => {
      data.packageToShow.forEach((packaget) => {
        const product = this.products.filter((p) => p.idproduct === packaget.idproduct);
        productsAux.push(product[0]);
        variants.push(packaget.variant);
        discounts.set(product[0].idproduct as number, packaget.discount);
      })
    })
    const ref = this.dialogService.open(ScheduleComponent, {
      closable: true,
      dismissableMask: true,
      showHeader: false,
      width: this.isMobile ? '100vw' : width,
      height: this.isMobile ? '100vh' : height,
      contentStyle: {'overflow-x': 'hidden'},
      data: {products: productsAux, discounts, coupon : this.couponDiscount, variants}
    });
    ref.onClose.subscribe((data: any) => {
      if (data.data) {
        localStorage.removeItem("sells");
        this.shopCartService.clearCart();
        setTimeout(() => {
          this.router.navigate(["/history"]);
        }, 1000);
      }
    });
  }

  get isDisabled(){
    const a = (this.totalItems == 0 || !this.enableCheckout || !this.isCompatibleAddress && this.hasShippingProducts || this.loadingDeliveryInfo) && this.type == 1 ;
    const b = !this.enableCheckout && this.type == 2;
    return (a || b) && this.islogged;
  }

  goToHome() {
    this.router.navigate(['/home'])
  }

  emitCreateOrChangeAddress() {
    if (this.displaySideBarCart) {
      this.initSideBarCart();
    }
  }

  goToFollow(){
    if (this.islogged){
      this.router.navigate(["/history"]);
    }else{
      this.displayRedirect = true;
    }
  }

  goToFollowNA(){
    this.router.navigate(["/history"], {queryParams: {sells: this.saleNumber}});
  }

  apply(){
    lastValueFrom(this.couponService.checkCoupon(this.couponCode.toUpperCase())).then((data: any) => {
      if (data.campaing.promotion_type === 1){
        if(this.couponDelivery){
          this.messageService.add({severity: "error", summary: "Error", detail: 'Ya tiene un cupón de envio aplicado', life: 4000});
        }else{
          this.couponDelivery = data;
          this.sumTotalDeliveryPrice = 0;
          this.sumTotalInstallPrice = 0;
          this.setPackageInformation("allTypes");
          this.initializeVariables();
          this.getProducts();
          this.showCouponDialog = false;
        }
      }else{
        if(this.couponDiscount || this.sumTotalDiscount > 0){
          this.messageService.add({severity: "error", summary: "Error", detail: 'Hay productos en la compra con descuento solo se permiten cupones de Envíos gratis', life: 4000});
        }else{
          this.couponDiscount = data;
          this.sumTotalSubTotal = 0;
          this.sumTotalDiscount = 0;
          this.sumTotalDeliveryPrice = 0;
          this.sumTotalInstallPrice = 0;
          this.deliveryCommodityCart = this.shopCartService.deliveryCommodityCar.value;
          this.deliveryLiquidCart = this.shopCartService.deliveryLiquidCar.value
          this.deliveryParcelCart= this.shopCartService.deliveryParcelCar.value
          this.getProducts();
          this.showCouponDialog = false;
        }
      }
    }).catch((error) => {
      this.messageService.add({severity: "error", summary: "Error", detail: error.error.Error, life: 4000});
    })
  }

  changeType(type: any){
    this.sumTotalDeliveryPrice = 0;
    this.initializeVariables();
    this.getProducts();
    this.availability = new Map<string, boolean>();
    this.setNewAvailabilityMap();
    this.getAvailability();
  }

  toPayment(){
    if(this.paymentMethod  === "epayco"){
      this.sistecredit = false;
      this.contra = false;
      this.pay();
    }else if (this.paymentMethod === "sistecredit"){
      this.sistecreditDialog = true;
      this.sistecredit = true;
      this.contra = false;
    }else if (this.paymentMethod === "contraentrega"){
      this.sistecredit = false;
      this.contra = true;
      this.showContra();
    }else {
      this.addiDialog = true;
    }
  }

  async showContra(){
    const infoUser = await lastValueFrom(this.userProfileService.getInfoUser())
    const ref = this.dialogService.open(ContraentregaComponent, {
      data: {
        address: this.userAddress.address,
        extra: this.userAddress.description,
        total: this.total,
        location: this.locationId,
        name: infoUser[0].auth_user.first_name,
        last_name: infoUser[0].auth_user.last_name,
        phone: infoUser[0].celphone
      },
      dismissableMask: false,
      showHeader: true,
      width: this.isMobile ? '100vw' : '30%',
      height:  this.isMobile ? '100vh' : '70%'});
    ref.onClose.subscribe((data) => {
      if(data){
        this.pay(infoUser, data);
      }
    })
  }


  async pay(infoUser?: any, data?: any): Promise<void> {
    if(this.paymentMethod == "addi"){
      this.loadingAddi = true;
    }
    if(!infoUser){
      infoUser = await lastValueFrom(this.userProfileService.getInfoUser())
    } 
    const enviosObj = await this.checkoutService.infoGeneral(infoUser, this.date, this.userAddress, this.userCityCodeDane);
    const sells: Sell[] = [];
    const quantity = this.deliveryParcelCart.sells.length + this.deliveryCommodityCart.sells.length + this.deliveryLiquidCart.sells.length;
    this.semaphore.next(quantity);
    let currentAddress: any = null;
    if (this.deliveryParcelCart.sells.length > 0) {
        for (let i = 0; i < this.deliveryParcelCart.sells.length; i++) {
          const sell = this.deliveryParcelCart.sells[i];
          try {
            let delivery: any;
            let deliveryPrize = 0;
            if(this.type === 1){
              delivery = await this.infoEnvio(sell.workshop, sell.sellLines, enviosObj, "Parcel", false);
              if (delivery instanceof HttpErrorResponse) {
                throw new Error(delivery.error.error);
              }
              deliveryPrize = this.deliveryPackagesParcelCart[0].realDeliverPrice;
            }
            this.createSells(sell, sells, this.type, delivery, deliveryPrize);
          } catch (error) {
            console.log(error);
          }
        }
    }
    if (this.deliveryCommodityCart.sells.length > 0) {
        for (let i = 0; i < this.deliveryCommodityCart.sells.length; i++) {
          const sell = this.deliveryCommodityCart.sells[i];
          try {
            let delivery: any;
            let deliveryPrize = 0;
            if(this.type === 1){
              delivery = await this.infoEnvio(sell.workshop, sell.sellLines, enviosObj, "Commodity", false);
              if (delivery instanceof HttpErrorResponse) {
                throw new Error(delivery.error.error);
              }
              deliveryPrize = this.deliveryPackagesCommodityCart[0].realDeliverPrice;
            } 
            this.createSells(sell, sells, this.type, delivery, deliveryPrize);
          } catch (error) {
            console.log("error");
          }
        }
    }
    if (this.deliveryLiquidCart.sells.length > 0) {
        for (let i = 0; i < this.deliveryLiquidCart.sells.length; i++) {
          const sell = this.deliveryLiquidCart.sells[i];
          try {
            let delivery: any;
            let deliveryPrize = 0;
            if(this.type === 1){
              delivery = await this.infoEnvio(sell.workshop, sell.sellLines, enviosObj, "liquid", false);
              if (delivery instanceof HttpErrorResponse) {
                throw new Error(delivery.error.error);
              }
              deliveryPrize = this.deliveryPackagesLiquidCart[0].realDeliverPrice;
            }
            this.createSells(sell, sells, this.type, delivery, deliveryPrize);
          } catch (error) {
            console.log("error");
          }
        }
    }
    sells.forEach((sell) => {
      if(this.paymentMethod === "contraentrega"){
        sell!.franchie = "CON";
        sell!.payment_gateway_id = sell?.delivery?.remission_code;
        sell!.payed_value = this.total;
        sell!.payed_date = new Date();
        sell!.user_number_id = data.idNumber;
        sell!.user_type_id = data.idType
      }else if (this.paymentMethod === "addi"){
        sell!.user_type_id = 'Cédula de ciudadanía';
        sell!.user_number_id = this.idnumber;
        sell!.email = this.email;
      }
      this.delivery_service.checkout(sell).then(async (data:any) => {
        this.sellsCreated.push(data);
        this.sellsIdChecked.push(data.idventa);
        if(this.paymentMethod === "contraentrega" && environment.production){
          lastValueFrom(this.delivery_service.createBill(data.idventa)).catch((error) => {
            console.log(error);
          })
        }
        this.semaphore.next(this.semaphore.value - 1);
      }).catch((error:Error) => {
        this.messageService.add({severity: "error", summary: "Error", detail: 'Hubo un error al momento de crear tu compra, inténtalo mas tarde.', life: 4000});
      });
    });
  }

  private createSells(sell: ShopCarSells, sells: Sell[], sellType: number, delivery?:any, deliveryPrize?:any): void {
    let aux: Sell;
    const sellLines = sell.sellLines.map((sellLine:any) => {
      return { units: sellLine.units, product_idproduct: sellLine.product, variant: sellLine.variant };
    });
    if (sellType == 1) {
        aux = { sell_lines: sellLines, workshop: sell.workshop, sell_type: sellType, delivery_prize: deliveryPrize,
                delivery: {
                  guide: delivery.guide, remission_code: delivery.remission_code, userAddress: delivery.userAddress,
                  userLocation: delivery.userLocation, ally_addres: delivery.ally_Address,
                  ally_location: delivery.ally_Location, guide_link: delivery.guide_link, user_description: delivery.user_description
                }, from_webpage: true, coupon_discount: this.couponDiscount?.id, coupon_delivery: this.couponDelivery?.id
              };
    } else if (sellType == 0) {
        aux = { sell_lines: sellLines, workshop: sell.workshop, sell_type: sellType, from_webpage: true,
          coupon_discount: this.couponDiscount?.id, coupon_delivery: this.couponDelivery?.id };
    }
    // @ts-ignore
    sells.push(aux);
  }
  

  async infoEnvio(workshopId: number, sellLines: SellLine[], enviosObj: Partial<ShippingObject>, shippingType: string, is_liquid?:boolean){
    const enviosObjInfo = {... enviosObj}
    let detalles: any = [];
    const contenido: any = [];
    let valorDeclarado = 0;
    let names = "";
    let dimensionsAux: PackageDimensions = {
      alto: 0,
      ancho: 0,
      largo: 0,
      peso: 0,
      unidades: 1,
    }
    let max_volume = 0
    sellLines.forEach((sellLine: any, index) => {
      const detalleProduct:any = this.getProductInfo(workshopId, sellLine.product, sellLine.units, sellLine.variant);
      if (detalleProduct.discount > 0) {
        const originalPrice = (detalleProduct.price * (1 + (detalleProduct.tax / 100))) - detalleProduct.discount;
        const pricewithouthIva = Math.ceil(originalPrice / (1 + (detalleProduct.tax / 100)));
        valorDeclarado += pricewithouthIva;
      } else {
        valorDeclarado += detalleProduct.price;
      }
      const dimensions = detalleProduct.dimensions
      let volume = dimensions.alto * dimensions.ancho * dimensions.largo;
      if(volume > max_volume){
        max_volume = volume;
        detalles = [detalleProduct.dimensions];
      }
      contenido.push(detalleProduct.name);
      names = names+detalleProduct.name[0]
    });
    const data = await this.alliesService.getInfoMarker(workshopId).catch(() => console.log("error"));
    const dir = data.address.indexOf(",");
    enviosObjInfo.nit_remitente = data.enterprise.number_id;
    enviosObjInfo.nombre_remitente = data.visible_name;
    enviosObjInfo.direccion_remitente = data.address.slice(0, dir);
    enviosObjInfo.telefono_remitente = data.phoneNumber;
    enviosObjInfo.ciudad_remitente = data.city.dane_code;
    enviosObjInfo.detalles = detalles;
    enviosObjInfo.contenido = contenido.join().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace("ñ", "n").replace("Ñ", "N");
    enviosObjInfo.valor_declarado = valorDeclarado;
    enviosObjInfo.observaciones = this.observaciones.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace("ñ", "n").replace("Ñ", "N");
    try {
      let dataResp: any = undefined;
      if (this.paymentMethod === 'contraentrega'){
        let nameaux = enviosObj.nombre_remitente?.charAt(0)
        const unixTime = Math.floor(Date.now() / 1000);
        enviosObjInfo.referencia = names + (nameaux !== undefined ? nameaux : '') + unixTime + ""
        enviosObjInfo.valor = this.total + ""
        dataResp = await this.delivery_service.postGuiaContra(enviosObjInfo);
      }else{
        // @ts-ignore
        dataResp = await this.delivery_service.postGuia(enviosObjInfo, is_liquid);
      }
      const dirUser = this.userAddress.address.indexOf(",");
      const guide = dataResp.id_remision["#text"];
      const remission_code = dataResp.codigo_remision["#text"];
      const userAddress = this.userAddress.address.slice(0, dirUser);
      const ally_Address = data.address.slice(0, dir);
      const ally_Location = data.city.id;
      const guide_link = dataResp.url_terceros["#text"];
      const user_description = this.userAddress.description.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace("ñ", "n").replace("Ñ", "N");
      const delivery: DeliveryObject = {
          guide, remission_code, userLocation: this.locationId,
          userAddress, ally_Address, ally_Location, guide_link, user_description
      };
      return delivery;
    } catch (error:any) {
      console.log(error);
      if (error.error?.error) {
      } else {
      }
      return error;
    }
  }

  getTaxProduct(idProduct:any) {
    const product:any = this.products.find(resp => resp.idproduct == idProduct);
    return product.tax;
  }

  checkoutTrigg(id: string) {
    // push GTM data layer with a custom event
    const gtmTag = {
      event: 'begin_checkout',
      data: {currency: 'COP', value: this.total},
    };
    this.gtmService.pushTag(gtmTag);
    this.pixel.track('InitiateCheckout', {
      content_category: 'page',
      content_name : 'Checkout',
      currency: 'COP',
      value: this.total,
    }, {event_id: id});
  }


  private async afterPayment() {

    let base:number = 0;
    let tax:number = 0;
    let names:string[] = [];

    if (this.deliveryPackagesParcelCart.length > 0) {
      await this.deliveryPackagesParcelCart.forEach( async pack => { 
        await pack.package.forEach(product => {
          let units = this.getUnits(pack.packageToShow,product.name, product.variantName);
          let price = product.price - product.discount;
          let taxProduct:any = this.getTaxProduct(product.idproduct)? this.getTaxProduct(product.idproduct) : 0;
          let base_price = price / (1 + (taxProduct / 100));
          base = base + (base_price * units);
          tax = tax + ((base_price * (taxProduct / 100)) * units);
          names.push(product.name);
          
        });
        /* base = base + pack.deliveryPrice; */
      });
    }
    setTimeout(async () => {
      if (this.deliveryPackagesCommodityCart.length > 0) {
        await this.deliveryPackagesCommodityCart.forEach( async pack => { 
          await pack.package.forEach(product => {
            let units = this.getUnits(pack.packageToShow,product.name, product.variantName);
            let price = product.price - product.discount;
            let taxProduct:any = this.getTaxProduct(product.idproduct)? this.getTaxProduct(product.idproduct) : 0;
            let base_price = price / (1 + (taxProduct / 100));
            base = base + (base_price * units);
            tax = tax + ((base_price * (taxProduct / 100)) * units);
            names.push(product.name);
          });
          /* base = base + pack.deliveryPrice; */
        });
      }
    }, 0);

    setTimeout(async () => {
      if (this.deliveryPackagesLiquidCart.length > 0) {
        await this.deliveryPackagesLiquidCart.forEach( async pack => { 
          await pack.package.forEach(product => {
            let units = this.getUnits(pack.packageToShow,product.name, product.variantName);
            let price = product.price - product.discount;
            let taxProduct:any = this.getTaxProduct(product.idproduct)? this.getTaxProduct(product.idproduct) : 0;
            let base_price = price / (1 + (taxProduct / 100));
            base = base + (base_price * units);
            tax = tax + ((base_price * (taxProduct / 100)) * units);
            names.push(product.name);
          });
          /* base = base + pack.deliveryPrice; */
        });
      }
    }, 0);
    const orderId = this.sellsIdChecked.join(";")
    if (this.sistecredit){
      this.checkoutTrigg(orderId);
      const body = {
        idDocument: this.idnumber,
        typeDocument: this.idType.value,
        transactionDate: new Date(),
        valueToPaid: this.total,
        vendorId: environment.vendorId,
        storeId: environment.storeId,
        orderId,
        responseUrl: environment.checkout_url + "/sistecreditoWeb"
      }
      const bodyfire = {data : this.sellsIdChecked}
      await this.buysService.saveSell(orderId,bodyfire)
      await this.metaSellService.saveSell(orderId);
      lastValueFrom(this.buysService.getSisteLink(body)).then(response => {
        const url = response.data.urlToRedirect + "?transactionId=" + response.data.transactionId;
        window.location.replace(url);
        localStorage.removeItem("sells");
        localStorage.setItem("sells", JSON.stringify(this.sellsIdChecked));
        this.sellsIdChecked = [];
      }).catch((error) => {
        this.messageService.add({severity: "error", summary: "Error", detail: 'Existe una transacción en curso, inténtalo más tarde.', life: 4000});
      });
    }else if (this.paymentMethod === "contraentrega"){
      await this.metaSellService.saveSell(orderId);
      this.checkoutTrigg(orderId);
      this.paymentDialog = false;
      this.router.navigateByUrl(`payment/accepted?ids=${this.sellsIdChecked.join(";")}`)
    }
    else if (this.paymentMethod === "epayco"){
      this.checkoutTrigg(orderId);
      let script = this._renderer2.createElement('script');
      const ownUrl = window.location.hostname;
      script.type = `text/javascript`;
      script.text = `
        var handler = ePayco.checkout.configure({
          key: '4bb75aee733562d3de6aaccfb9f7390a',
          test: ${environment.production ? 'false' : 'true'}
        });
        var data={
          //Parametros compra (obligatorio)
          name: "Compra Zaito",
          description: "${names.toString()}",
          currency: "cop",
          amount: ${this.total},
          tax_base: ${base},
          tax: ${tax},
          country: "co",
          lang: "es",
  
          //Onpage="false" - Standard="true"
          external: "false",
  
          extra1: "${this.sellsIdChecked.join(";")}",
          extra2: "${this._fbp}",
          //Atributos opcionales
          confirmation: "${environment.checkout_url}/payweb/",
          acepted: "https://www.zaito.co/payment/accepted?ids=${this.sellsIdChecked.join(";")}",
          pending: "https://www.zaito.co/payment/pending",
          rejected: "https://www.zaito.co/payment/rejected",
          response: "https://www.zaito.co/",
        }
        handler.open(data)`;
        this._renderer2.appendChild(this._document.body, script);
        this.sellsIdChecked = [];
    }else{
      await this.metaSellService.saveSell(orderId);
      this.checkoutTrigg(orderId);
      this.addiService.getLink(this.sellsIdChecked.join(";")).subscribe((link) => {
        window.location.replace(link.url)
        this.loadingAddi = false;
      }, error => {
        console.log(error);
        this.loadingAddi = false;
        this.addiDialog = false;
      })
    }
    
  }

  getUnits(packages: any,productName: any, variantName?: any){
    let cont = 0;
    packages.forEach((pack: any) => {
      if (pack.name === productName){
        if(variantName){
          if(variantName === pack.variantName){
            cont++;
          }
        }else{
          cont++;
        }
      } 
    })
    return cont;
  }

}
