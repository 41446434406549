<ng-container>
    <p-badge *ngIf="numberProductsInCart > 0" [value]="numberProductsInCart2"></p-badge>
    <span class="material-symbols-rounded icon fill" (click)="openSidebarCart()">shopping_cart</span>
</ng-container>

<p-sidebar [(visible)]="displaySideBarCart" position="right" [showCloseIcon]="false" class="sidebar-cart"
            (onShow)="initSideBarCart()" (onHide)="hideSideBarCart($event)">
    <ng-template pTemplate="header">
        <div class="col-12 flex header-sidebar">
            <div class="col-4">
                <h2 class="m-0 title">Carrito</h2>
            </div>
            <div class = "col-7 flex justify-content-end align-items-center" (click) = "goToFollow()"
            style = "font-weight: 600;color:var(--azul-zaito-4);cursor: pointer" >
                <span class="material-symbols-sharp">
                    local_mall
                </span>
                {{islogged ? "Mis compras" : "Rastrear Compra"}}
            </div>
            <div class="col-1 flex" style="justify-content: flex-end;">
                <span class="material-symbols-rounded fill icon-close" (click)="displaySideBarCart = false">cancel</span>
            </div>
        </div>
    </ng-template>
    <ng-template pTemplate="content">
        <ng-container *ngIf="loaded; else skeletonChanged">
            <div *ngIf="totalItems > 0 && products.length > 0; else empty">
                <div *ngIf="dateCart">
                    <div style="width: 100%;" *ngFor="let sell of dateCart.sells">
                        <app-cart-product *ngFor="let line of sell.sellLines; index as i"
                            [product]="getProduct(line.product)" [line]="line" [sellType]="type"
                            [loadingDeliveryInfo]="loadingDeliveryInfo"
                            (finalPrice)="sumTotal($event)"
                            (available)="setAvailability(type,line.product,$event,i)"
                            (remove)="removeItemInCart($event,sell.workshop,i)" 
                            (updateValue)="updateTotal($event, line)"
                        ></app-cart-product>
                    </div>
                </div>
                <div *ngIf="deliveryParcelCart">
                    <div style="width: 100%;" *ngFor="let sell of deliveryParcelCart.sells">
                        <app-cart-product *ngFor="let line of sell.sellLines;index as i"
                            [product]="getProduct(line.product)" [line]="line" [sellType]="type"
                            [loadingDeliveryInfo]="loadingDeliveryInfo"
                            (finalPrice)="sumTotal($event)"
                            (available)="setAvailability(type,line.product,$event,i)"
                            (remove)="removeItemInCart($event,sell.workshop,i,getProduct(line.product).dimensions?.weight)"
                            (updateValue)="updateTotal($event, line)"
                        ></app-cart-product>
                    </div>
                </div>
                <div *ngIf="deliveryCommodityCart">
                    <div style="width: 100%;" *ngFor="let sell of deliveryCommodityCart.sells">
                        <app-cart-product *ngFor="let line of sell.sellLines;index as i"
                            [product]="getProduct(line.product)" [line]="line" [sellType]="type"
                            [loadingDeliveryInfo]="loadingDeliveryInfo"
                            (finalPrice)="sumTotal($event)"
                            (available)="setAvailability(type,line.product,$event,i)"
                            (remove)="removeItemInCart($event,sell.workshop,i,getProduct(line.product).dimensions?.weight)"
                            (updateValue)="updateTotal($event, line)"
                        ></app-cart-product>
                    </div>
                </div>
                <div *ngIf="deliveryLiquidCart">
                    <div style="width: 100%;" *ngFor="let sell of deliveryLiquidCart.sells">
                        <app-cart-product *ngFor="let line of sell.sellLines; index as i"
                            [product]="getProduct(line.product)" [line]="line" [sellType]="type"
                            [loadingDeliveryInfo]="loadingDeliveryInfo"
                            (finalPrice)="sumTotal($event)"
                            (available)="setAvailability(type,line.product,$event,i)"
                            (remove)="removeItemInCart($event,sell.workshop,i)"
                            (updateValue)="updateTotal($event, line)"
                        ></app-cart-product>
                    </div>
                </div>
                <div *ngIf="retreatCart">
                    <div style="width: 100%;" *ngFor="let sell of retreatCart.sells">
                        <app-cart-product *ngFor="let line of sell.sellLines; index as i"
                            [product]="getProduct(line.product)" [line]="line" [sellType]="type"
                            [loadingDeliveryInfo]="loadingDeliveryInfo"
                            (finalPrice)="sumTotal($event)"
                            (available)="setAvailability(type,line.product,$event,i)"
                            (remove)="removeItemInCart($event,sell.workshop,i)"
                            (updateValue)="updateTotal($event, line)"
                        ></app-cart-product>
                    </div>
                </div>
            </div>
            <ng-template #empty>
                <ng-container *ngIf="!loaded && totalItems > 0; else nothing">
                    <div class="ion-text-center" style="height: 100%; width: 100%;">
                        
                    </div>
                </ng-container>
                <ng-template #nothing>
                    <div class="empty-message-container" style="text-align: center; margin-top: 50%;">
                        <h6 data-testid="empty-message" style="color: var(--gris-zaito-4); font-size: 85%;">
                            Aún no tienes productos agregados en el carrito...
                        </h6>
                    </div>
                </ng-template>
            </ng-template>
        </ng-container>
        <ng-template #skeletonChanged>
            <app-skeleton-text [value]="2"></app-skeleton-text>
        </ng-template>
    </ng-template>
    <ng-template pTemplate="footer">
        <ng-container *ngIf="loaded">
            <div class="separated-gray-line" style="margin: 0;"></div>
            <div class="col-12" style="padding: 0 5%;">
                <div class = "col-12 mb-2 mt-2" *ngIf = "islogged"  style="font-size: 90%; font-weight: 600; color: black;">
                    Elije la forma de entrega
                </div>
                <div class = "col-12 mb-2" style = "display: flex;flex-direction: column;" *ngIf = "islogged" >
                    <div class="flex align-items-center mb-3"  style="font-size: 80%;">
                        <p-radioButton name="envios" value=1 (click)="changeType(1)"
                        [(ngModel)]="type" inputId="envios" ></p-radioButton>
                        <label for="ingredient1" class="ml-2"
                        [style.font-weight] = "type == 1 ? '600' : '500'"
                        [style.color] = "type == 1 ? 'var(--azul-zaito-5)' : 'var(--gris-zaito-4)'">
                            Enviar a domicilio.
                        </label>
                    </div>
                    
                    <div class="flex align-items-center mb-3"  style="font-size: 80%; ">
                        <p-radioButton name="retiros" value=0 (click)="changeType(0)"
                        [(ngModel)]="type" inputId="retiros" ></p-radioButton>
                        <label for="ingredient2" class="ml-2"
                        [style.font-weight] = "type == 0 ? '600' : '500'"
                        [style.color] = "type == 0 ? 'var(--azul-zaito-5)' : 'var(--gris-zaito-4)'">
                            Retirar en un punto de entrega.
                        </label>
                    </div>

                    <div class="flex align-items-center "  style="font-size: 80%; ">
                        <p-radioButton name="citas" value=2 (click)="changeType(2)"
                        [(ngModel)]="type" inputId="citas" ></p-radioButton>
                        <label for="ingredient3" class="ml-2"
                        [style.font-weight] = "type == 0 ? '600' : '500'"
                        [style.color] = "type == 0 ? 'var(--azul-zaito-5)' : 'var(--gris-zaito-4)'">
                            Con instalación en nuestro taller.
                        </label>
                    </div>
                                    
                </div>
                <div class = "col-12 flex justify-content-center" 
                *ngIf="islogged && type == 0"
                style = "margin-bottom: 0;padding-bottom: 0;color:black;text-align: center;font-size: 80%;">
                    <i>*Disponible solo para entrega en la sede de <br>Itagüí Antioquia.</i>
                </div>
                <div class = "col-12 flex justify-content-center mb-3" 
                *ngIf="islogged && type == 0"
                style = "margin-bottom: 0;padding-bottom: 0;color:black;text-align: center;font-size: 80%;">
                    <i> Horarios: Lunes a Viernes de 8 am - 5pm, <br> 
                        Sabados 8 am - 12 pm
                    </i>
                </div>

                <a *ngIf="type==0 || type == 2"  href="https://www.google.com/maps/place/Zaito/@6.1788948,-75.5960059,17z/data=!3m1!4b1!4m6!3m5!1s0x8e4682803c811f6d:0x8a26fa94e8f49c2f!8m2!3d6.1788948!4d-75.593431!16s%2Fg%2F11rylmqnrc?hl=es-419&entry=ttu" target="_blank">
                    <div class = "col-12" >
                        <div class = "grid" style = "width: 100%;display: flex;justify-content: center;">
                            <div class = "col-12" style = "border: 2px solid var(--gris-primeng);border-radius: 6px;padding-bottom: 0;">
                                <div class = "grid">
                                    <div class = "col-9 flex align-items-center" 
                                    style = "font-size: 0.7em;text-align: center;padding-left: 40px;color:var(--azul-zaito-5);font-weight: 500;">
                                        Carrera 42 # 67a -191 interior 114-2<br>
                                        Centro Empresarial Capricentro <br>Itagüi-Antioquia
                                    </div>
                                    <div class = "col-3 flex align-items-center justify-content-center">
                                        <img style = "width: 70%; height: 70%;"
                                        src = "https://upload.wikimedia.org/wikipedia/commons/thumb/b/bd/Google_Maps_Logo_2020.svg/512px-Google_Maps_Logo_2020.svg.png">
                                    </div>  
                                </div>
                            </div>
                           
                        </div>
                    </div>
                </a>
                <div class="col-12 p-0 flex" *ngIf = "islogged && type == 1">
                    <div class="col-12">
                        <p class="m-0 mb-1" style="font-size: 80%; font-weight: 500; color: black;">Dirección de Envíos</p>
                        <app-address comesFrom="cart" (changeOrAddAddress)="emitCreateOrChangeAddress()"></app-address>
                    </div>
                </div>
                <div *ngIf="!isCompatibleAddress && totalItems != 0 && islogged && type == 1" class="no-coverage col-12 m-0 p-0">
                    <p style="font-weight: 500; font-size: 0.7rem; text-align: center; margin: 0;">*Sin cobertura de envíos para tu dirección actual</p>
                </div>
                <div class="col-12 p-0 flex deliveryAndDate-card-time" *ngIf = "islogged && type == 1">
                    <div class = "col-12">
                        <p *ngIf="shippingDays === 1">
                            *Llega aproximadamente en {{shippingDays}} día
                          </p>
                          <p *ngIf="shippingDays > 1">
                            *Llega aproximadamente en {{shippingDays}} días
                          </p>
                    </div>
                </div>
                <div class="col-12 flex container-total">
                    <div class="col-6">
                        <p class="m-0">Subtotal</p>
                    </div>
                    <div class="col-6">
                        <p class="m-0" style="text-align: right;">
                            <span *ngIf="totalItems != 0">+ $</span>{{ totalItems == 0? '-' : sumTotalSubTotal | number }}
                        </p>
                    </div>
                    <div class="col-6" *ngIf="(sumTotalDeliveryPrice> 0 || couponDelivery) && type == 1">
                        <p class="m-0">Costo Envío</p>
                    </div>
                    <div class="col-6"  *ngIf="(sumTotalDeliveryPrice> 0) && type == 1;else freeDel">
                        <p class="m-0" style="text-align: right;"> <span *ngIf="totalItems != 0">
                            + $</span>{{ totalItems == 0? '-' : sumTotalDeliveryPrice | number }}
                        </p>
                    </div>
                    <div class="col-6" *ngIf="type == 2">
                        <p class="m-0">Mano de obra y/o instalación</p>
                    </div>
                    <div class="col-6"  *ngIf="type == 2;">
                        <p class="m-0" style="text-align: right;" *ngIf = "(sumTotalInstallPrice> 0) "> <span *ngIf="totalItems != 0 ">
                            + $</span>{{ totalItems == 0? '-' : sumTotalInstallPrice | number }}
                        </p>
                        <p class="m-0" style="text-align: right;color: var(--verde-zaito-2);"  *ngIf = "(sumTotalInstallPrice == 0) ">
                            Incluida
                        </p>
                    </div>
                    <ng-template #freeDel>
                        <div class="col-6" style = "display: flex;justify-content: flex-end;"  
                        *ngIf = "islogged && type == 1 && this.hasShippingProducts && isCompatibleAddress">
                            <p class = "m-0"style="color:  var(--verde-zaito-2)">+Envio Gratis </p>
                        </div>
                    </ng-template>
                    <div class="col-6" *ngIf = "islogged" >
                        <p class="m-0">Descuento</p>
                    </div>
                    <div class="col-6 flex justify-content-end" *ngIf = "islogged">
                        <div class = "grid">
                            <div class = "col-12 flex justify-content-end align-items-end" style = "flex-direction: column;">
                                <span  style = "font-weight: 500; color: black;font-size: 90%;"
                                *ngIf="totalItems != 0 && sumTotalDiscount != 0 && !couponDiscount">- $
                                    <span *ngIf="sumTotalDiscount != 0 && !couponDiscount;">
                                        {{ sumTotalDiscount == 0? '-' : sumTotalDiscount | number }}
                                    </span>
                                </span>
                                <span  *ngIf="couponDiscount" class = "discountCoupon">
                                    - ${{sumTotalDiscount | number}}
                                    <br> <p style="color:  var(--verde-zaito-2);font-weight: 600;"> Cupón {{couponDiscount.value}} Aplicado!</p>
                                </span>
                                <span 
                                style="color:  var(--verde-zaito-2);text-align: end;font-size: 14px;font-weight: 600;" *ngIf="couponDelivery">
                                    Cupón {{couponDelivery.value}} Aplicado!
                                </span>
                                <span 
                                style="color:  var(--verde-zaito-2);cursor:pointer;font-weight: 600;" *ngIf="((!(couponDelivery && type == 1) || !couponDiscount))"
                                (click)="showCouponDialog=true">Redimir Cupón</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class ="col-12 paymentAdvice" *ngIf = "type == 2">
                    *El pago total se realiza al final de la cita; en Zaito el cumplimiento
                    es primordial para garantizarte un excelente servicio
                </div>
                <div class ="col-12" style = "display: flex;justify-content: center;padding-top: 0rem;" *ngIf = "type == 2">
                    <img src = "../../../assets/img/shared/Metodos-de-Pago-3.0-Citas.png" style = "width: 51%;">
                </div>
                <div class="col-12">
                    <p-button pRipple class="ir-a-pagar" 
                            [disabled]="isDisabled" 
                            (onClick)="goToCheckout()">
                        <span style="margin-right: auto;">{{type != 2 ? 'Ir al Checkout': 'Ir a agendar'}}</span>
                        ${{ total | number }}
                    </p-button>
                </div>
            </div>
        </ng-container>
    </ng-template>
</p-sidebar>

<p-sidebar [(visible)]="displaySideBarCheckout" position="right" [showCloseIcon]="false" class="sidebar-checkout" (onShow)="initSideBarCheckout($event)">
    <ng-template pTemplate="header">
        <div class="col-12 flex header-sidebar">
            <div class="col-6">
                <p class="m-0 title">Checkout</p>
            </div>
            <div class="col-6 flex" style="justify-content: flex-end;">
                <span class="material-symbols-rounded fill icon-close" (click)="displaySideBarCheckout = false">cancel</span>
            </div>
        </div>
    </ng-template>
    <ng-template pTemplate="content">  
        <app-modal-checkout></app-modal-checkout>
    </ng-template>
    <ng-template pTemplate="footer">
    </ng-template>
</p-sidebar>

<p-dialog [(visible)]="displayRedirect" [modal]="true" [closable]="false"  class="phoneDialog" [draggable]="false"  >
    <ng-template pTemplate="header">
        <div class = "col-12" (click) ="displayRedirect = false" >
            <span class="material-symbols-rounded fill icon-dialogs" style="float: right;">cancel</span>
        </div>
    </ng-template>
    <div class = "grid">
        <div class="col-12 flex justify-content-center" style = "color: black;font-weight: 600;">
            Ingrese Id de la Compra
        </div>  
        <div class = "col-12 flex justify-content-center">
            <input type="text" id="saleNumber" tye="text" [(ngModel)]="saleNumber" pInputText [ngStyle] = "{'font-size': '20px', 'width': '50%'}">
        </div>
        <div class = "col-6 col-offset-3 ">
            <p-button pRipple label="Continuar" (click) = "goToFollowNA()"
                class="button-continuar">
            </p-button>
        </div>
    </div>
    <!-- <button type="button" (click)="saveSaleNumber()" pButton label="Guardar"></button>
    <button type="button" (click)="closeDialog()" pButton label="Cancelar"></button> -->
</p-dialog>

<p-dialog  [(visible)]="showCouponDialog" 
    [style]="{ width: isMobile ? '90vw' : '25vw'}" [modal]="true" [closable]="false"  [draggable]="false">
    <ng-template pTemplate="header">
        <div class = "grid" style = "width: 100%;margin-bottom: 20px;">
            <div class = "col-10" style = "font-weight: 600;">
                Ingresa el Código de Cupón
            </div>
            <div class="col-2">
                <span class="material-symbols-rounded fill icon-dialogs" (click)="showCouponDialog = false" style="float: right;">cancel</span>
            </div>
        </div>
        
    </ng-template>
    <ng-template pTemplate="content">
        <div class = "p-grid">
            <div class = "col-12">
                <input pInputText [(ngModel)]="couponCode" 
                [style]="{'width': '100%',
                        'text-align': 'center',
                        'text-transform': 'uppercase',
                        'font-size': '16px',
                        'height': '40px',
                        'color': 'var(--azul-zaito-5)',
                        'font-weight': '600'}"/>
            </div>
        </div>
        <div class = "p-grid">
            <div class = "col-12">
                <p-button pRipple class = "button-buyNow mt-2" [style] = "{width: '100%'}" (click) = "apply()">
                    <span style="font-weight: 600;">Aplicar Cupón</span>
                </p-button>
            </div>
        </div>
        
    </ng-template>
    
</p-dialog>

<p-dialog  [(visible)]="noVehiclesToDateModal" 
    [style]="{ width: isMobile ? '90vw' : '25vw'}" [modal]="true" [closable]="true"  [draggable]="false">
    <ng-template pTemplate="content">
        <div class = "grid">
            <div class = "col-12" style = "text-align: center;font-weight: 600;color:var(--azul-zaito-5)">
                Para Agendar una cita necesitas una Moto <br>
                Creada para ver compatibilidad del producto <br>
                o servicio que te ofrecemos para brindarte la 
                mejor experiencia
            </div>
        </div>
        <div class = "grid justify-content-center flex mt-2">
            <div class = "col-3">
                <div class = "grid z-shadow-principal button-sidebar" 
                (click) = "noVehiclesToDateModal = false"
                [routerLink]="['/home']"
                style = "cursor: pointer;">
                    <div class = "col-6">
                        <img src="assets/img/home/MotoIcon.svg" alt="LOgo Moto">
                    </div>
                    <div class = "col-6">
                        <span class="material-symbols-rounded icon fill font-container">add_circle</span>
                    </div>
                </div>
            </div>
        </div>
        
    </ng-template>
    
</p-dialog>


<p-dialog [(visible)]="paymentDialog" [modal]="true" [closable] = "true" [showHeader]="false"
    [style]="{ width: isMobile ? '90vw' : '30vw'}" [dismissableMask]="true"  class = "dialogPayment">
    <div class = "container">
        <div class = "grid mt-3 mb-3" style = "padding-left: 2rem;padding-right: 2rem;">
            <div class = "col-12 p-3" style="font-weight: 600;color:black">
                Selecciona el Medio de Pago
            </div>
        </div>
        <div class = "horizontalLine">

        </div>
        <div class = "grid mt-3 mb-3" style = "padding-left: 2rem;padding-right: 2rem;">
            <div class = "col-12 flex align-items-center"
            style = "padding-top: 0px;padding-bottom: 0px;cursor: pointer;" 
            (click) = "paymentMethod = 'epayco'">
                <p-radioButton name="methods" value="epayco" [(ngModel)]="paymentMethod"></p-radioButton>
                <img src = "../../../assets/img/shared/Medios-pagos-ns.png" style = "height: 2em;" class = "ml-2">
            </div>
        </div>
        <div class = "horizontalLine">
        </div>
        <div class = "grid mt-3 mb-3" style = "padding-left: 2rem;padding-right: 2rem;">
            <div class = "col-12 flex align-items-center" 
            style = "padding-top: 0px;padding-bottom: 0px;cursor: pointer;" 
            (click) = "paymentMethod = 'sistecredit'">
                <p-radioButton name="methods" value="sistecredit" [(ngModel)]="paymentMethod"></p-radioButton>
                
                <div class = "paymentBanner ml-2" >
                  <img src = "../../../assets/img/shared/Sitecredito-logo-circle_1.png" style = "height: 28px;margin-right: 0.7em;">
                  Paga hasta <div *ngIf ="!isMobile" style = "margin-left: 0.4em">en</div> 
                  <span style = "color: var(--azul-zaito-5);font-weight: 500; margin-left: 0.3em;margin-right: 0.3em;">4 cuotas </span>
                  con 
                  <img src = "https://zaitostorage.blob.core.windows.net/logos-accesories/Sistecredito logo-svg.svg " style = "height: 9px;margin-left: 0.3em;">
                  <!-- <a style = "color:#0033A0; cursor: pointer;font-weight: 500;" class="underlined"> +Info</a> -->
                </div>
            </div>
        </div>
        <div class = "horizontalLine">
        </div>
        <div class = "grid mt-3 mb-3" style = "padding-left: 2rem;padding-right: 2rem;">
          <div class = "col-12 flex align-items-center"
          style = "padding-top: 0px;padding-bottom: 0px;;cursor: pointer;" 
          (click) = "paymentMethod = 'addi'">
              <p-radioButton name="methods" value="addi" [(ngModel)]="paymentMethod" *ngIf = "allow_addi"></p-radioButton>
              <div #addi class = "ml-2"></div>  
              <!-- <addi-widget price="{{total}}" ally-slug="zaito-ecommerce" class = "ml-2"></addi-widget> -->
          </div>
        </div>
        <div class = "horizontalLine" *ngIf = "allow_addi">
        </div>
        <div class = "grid mt-3 mb-3" style = "padding-left: 2rem;padding-right: 2rem;" *ngIf = "type == 1">
          <div class = "col-12 flex align-items-center" *ngIf = "roundSubTotal >= 90000;else nonCoordi"
          style = "padding-top: 0px;padding-bottom: 0px;cursor: pointer;">
              <p-radioButton name="methods" value="contraentrega" [(ngModel)]="paymentMethod"></p-radioButton>
              <img src = "../../../assets/img/shared/ContraEntregaCoordinadora.png" class = "ml-3 contraImg">
          </div>
          <ng-template #nonCoordi>
            <div class = "col-12 flex align-items-center"
              style = "padding-top: 0px;padding-bottom: 0px;cursor: pointer;">
                  <p-radioButton name="methods" value="contraentrega" [disabled]="true" [(ngModel)]="paymentMethod"></p-radioButton>
                  <img src = "../../../assets/img/shared/MinContraEntregaCoordinadoraX2.png" class = "ml-3 paymentOption">
              </div>
          </ng-template>
        </div>
        <div class = "horizontalLine" *ngIf = "type == 1">
        </div>
        <div class = "grid mt-3 mt-3 mb-3" style = "padding-left: 2rem;padding-right: 2rem;">
            <div class = "col-12">
                <p-button pRipple
                (onClick)="toPayment()"
                [disabled]="this.paymentMethod === undefined" 
                class="button-continuar">
                    <div class = "grid" style = "width: 100%;margin-top: 0.05em;font-size: 1.1rem;font-weight: 600;">
                        <div class = "col-6" style = "display: flex; align-items: center;justify-content: start;">
                          Ir a pagar
                        </div>
                        <div class = "col-6" style = "display: flex; align-items: center;justify-content: end;">
                            {{(total | number)}}
                        </div>
                    </div>
                </p-button>
            </div>
            
        </div>
    </div>
</p-dialog>


<p-dialog [(visible)]="sistecreditDialog" [modal]="true" [style]="{ width: isMobile ? '90vw' : '20vw'}" >
    <div class = "container">
        <div class = "grid ">
            <div class = "col-12 p-3">
                <img src = "https://zaitostorage.blob.core.windows.net/logos-accesories/Sistecredito logo-svg.svg" style = "height: 50%;" >
            </div>
        </div>
        <div class = "grid">
            <div class = "col-12 p-3" style="font-weight: 600;padding-top: 0 !important;">
              Digita tu número de documento para continuar.
            </div>
        </div>
        <div class = "grid" style = "padding-top: 0.5rem !important;">
            <div class = "col-12">
                <p-dropdown [options]="idTypes" optionLabel="value" placeholder="Tipo de documento" class="dropdown-select"  
                [(ngModel)]="idType">
                </p-dropdown>
            </div>
            <div class = "col-12" style = "padding-top: 0.5rem !important;">
                <input [(ngModel)]="idnumber"  pInputText style="width: 100%;">
            </div>
            <div class = "col-12" style = "margin-top: 1rem !important;">
                <p-button pRipple class = "button-buyNow mt-2" [disabled]="!(idnumber?.length > 0) || !idType"
                [style] = "{width: '100%'}" (click) = "this.loadingSiste = true;pay()" *ngIf = "!loadingSiste;else sisLoading">
                    <span style="font-weight: 600;">Continuar</span>
                </p-button>
                <ng-template #sisLoading>
                  <div class = "col-12 flex justify-content-center" >
                      <img src = "assets/img/shared/loading.gif" style = "width: 3rem;height: 3rem;" alt="loading">
                  </div>
                </ng-template>
            </div>
        </div>
    </div>
</p-dialog>

<p-dialog [(visible)]="addiDialog" [modal]="true" [style]="{ width: isMobile ? '90vw' : '20vw'}">
    <div class = "container">
        <div class = "grid">
          <div class = "col-12 mb-2">
              <img src="../../../../assets/img/shared/Addi-logo-zai.png">
          </div>
        </div>
        <div class = "grid">
            <div class = "col-12 p-3 mt-2" style="font-weight: 600;padding-top: 0 !important;">
                Digita tu número de documento y correo electrónico para continuar.
            </div>
        </div>
        <div class = "grid" style = "padding-top: 0.5rem !important;">
            <div class = "col-12" style = "padding: 0.5rem;" >
                <p-inputNumber [(ngModel)]="idnumber" placeholder="Numero de Documento" style = "width: 100%;" [useGrouping]="false"/>
            </div>
            <div class = "col-12">
                <input pInputText placeholder="Correo Electrónico" style = "padding:0.75rem 0.75rem; width: 100%;border: 1px solid" [(ngModel)]="email" />
            </div>
        </div>
        <div class = grid>
          <div class = "col-12" style = "padding: 0.5rem;" style = "margin-top: 1rem !important;">
              <p-button pRipple class = "button-buyNow mt-2" [disabled]="!(idnumber > 0) || !email"
              [style] = "{width: '100%'}" (click) = "(idnumber > 0) && email ? pay() : ''" *ngIf = "!loadingAddi;else AddiLoading">
                  <span style="font-weight: 600;">Continuar</span>
              </p-button>
              <ng-template #AddiLoading>
                  <div class = "col-12 flex justify-content-center" >
                      <img src = "assets/img/shared/loading.gif" style = "width: 3rem;height: 3rem;" alt="loading">
                  </div>
                </ng-template>
          </div>
        </div>
    </div>
</p-dialog>
  